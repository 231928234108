// extracted by mini-css-extract-plugin
export var agentCall = "callreview-module--agentCall--36f76";
export var billetShadow = "callreview-module--billet-shadow--b8ff3";
export var callBlock = "callreview-module--callBlock--89d6c";
export var callFilters = "callreview-module--callFilters--d026f";
export var callMiniReviewAbove = "callreview-module--callMiniReviewAbove--1f53d";
export var callMiniReviewBelow = "callreview-module--callMiniReviewBelow--08c4c";
export var callText = "callreview-module--callText--539c0";
export var callTextBottom = "callreview-module--callTextBottom--547ac";
export var callTextCallsLeft = "callreview-module--callTextCallsLeft--74a1b";
export var callTextCampaign = "callreview-module--callTextCampaign--df1ee";
export var callTextCenter = "callreview-module--callTextCenter--bb2a9";
export var callTextContent = "callreview-module--callTextContent--34632";
export var callTextDashboard = "callreview-module--callTextDashboard--3c76f";
export var callTextEmails = "callreview-module--callTextEmails--6c93d";
export var callTextEmailsContent = "callreview-module--callTextEmailsContent--89c43";
export var callTextFooter = "callreview-module--callTextFooter--23ffe";
export var callTextGroupPerformance = "callreview-module--callTextGroupPerformance--b92f4";
export var callTextModules = "callreview-module--callTextModules--22f61";
export var callTextModulesAgent = "callreview-module--callTextModulesAgent--87dbd";
export var callTextModulesCoaching = "callreview-module--callTextModulesCoaching--1e73a";
export var callTextModulesContent = "callreview-module--callTextModulesContent--ab845";
export var callTextModulesMissedPoints = "callreview-module--callTextModulesMissedPoints--1c59d";
export var callTextMyPay = "callreview-module--callTextMyPay--16f18";
export var callTextNIdashboard = "callreview-module--callTextNIdashboard--f6acc";
export var callTextNotifications = "callreview-module--callTextNotifications--c0092";
export var callTextScores = "callreview-module--callTextScores--de5d3";
export var callsLeftCall = "callreview-module--callsLeftCall--be591";
export var campaignCall = "callreview-module--campaignCall--c0247";
export var circle = "callreview-module--circle--10b06";
export var coachingCall = "callreview-module--coachingCall--5394a";
export var colFloat = "callreview-module--col-float--5c9cd";
export var colHeaderCall = "callreview-module--colHeaderCall--22fa6";
export var colLg1 = "callreview-module--col-lg-1--fd919";
export var colLg10 = "callreview-module--col-lg-10--45fd2";
export var colLg11 = "callreview-module--col-lg-11--29daa";
export var colLg12 = "callreview-module--col-lg-12--03d96";
export var colLg2 = "callreview-module--col-lg-2--ec076";
export var colLg3 = "callreview-module--col-lg-3--96351";
export var colLg4 = "callreview-module--col-lg-4--1a4de";
export var colLg5 = "callreview-module--col-lg-5--2db8f";
export var colLg6 = "callreview-module--col-lg-6--9c123";
export var colLg7 = "callreview-module--col-lg-7--a6718";
export var colLg8 = "callreview-module--col-lg-8--b7666";
export var colLg9 = "callreview-module--col-lg-9--d43fe";
export var colMd1 = "callreview-module--col-md-1--f1f5d";
export var colMd10 = "callreview-module--col-md-10--fd294";
export var colMd11 = "callreview-module--col-md-11--c9a5b";
export var colMd12 = "callreview-module--col-md-12--14337";
export var colMd2 = "callreview-module--col-md-2--d3ead";
export var colMd3 = "callreview-module--col-md-3--a158f";
export var colMd4 = "callreview-module--col-md-4--558a5";
export var colMd5 = "callreview-module--col-md-5--531d4";
export var colMd6 = "callreview-module--col-md-6--7363d";
export var colMd7 = "callreview-module--col-md-7--33b8e";
export var colMd8 = "callreview-module--col-md-8--c9794";
export var colMd9 = "callreview-module--col-md-9--740de";
export var colSm1 = "callreview-module--col-sm-1--6dfbf";
export var colSm10 = "callreview-module--col-sm-10--e7655";
export var colSm11 = "callreview-module--col-sm-11--d0eff";
export var colSm12 = "callreview-module--col-sm-12--11c97";
export var colSm2 = "callreview-module--col-sm-2--88078";
export var colSm3 = "callreview-module--col-sm-3--6434d";
export var colSm4 = "callreview-module--col-sm-4--9b05a";
export var colSm5 = "callreview-module--col-sm-5--6bf88";
export var colSm6 = "callreview-module--col-sm-6--7ed70";
export var colSm7 = "callreview-module--col-sm-7--316e5";
export var colSm8 = "callreview-module--col-sm-8--2f305";
export var colSm9 = "callreview-module--col-sm-9--a3b79";
export var colXs1 = "callreview-module--col-xs-1--6adaf";
export var colXs10 = "callreview-module--col-xs-10--ddcd6";
export var colXs11 = "callreview-module--col-xs-11--d1dd4";
export var colXs12 = "callreview-module--col-xs-12--b28e0";
export var colXs2 = "callreview-module--col-xs-2--a1bbd";
export var colXs3 = "callreview-module--col-xs-3--c4b36";
export var colXs4 = "callreview-module--col-xs-4--0662a";
export var colXs5 = "callreview-module--col-xs-5--2b979";
export var colXs6 = "callreview-module--col-xs-6--35651";
export var colXs7 = "callreview-module--col-xs-7--2f521";
export var colXs8 = "callreview-module--col-xs-8--47672";
export var colXs9 = "callreview-module--col-xs-9--3fa74";
export var colorScheme__background__grayPale = "callreview-module--colorScheme__background__gray-pale--e3be8";
export var contactForm = "callreview-module--contactForm--3ed15";
export var containerCall = "callreview-module--containerCall--1800f";
export var dashboardCall = "callreview-module--dashboardCall--7a953";
export var emailsCall = "callreview-module--emailsCall--d0c10";
export var figureAgent = "callreview-module--figureAgent--c4488";
export var figureCallsLeft = "callreview-module--figureCallsLeft--eea07";
export var figureCampaign = "callreview-module--figureCampaign--90ffc";
export var figureClipAgent = "callreview-module--figureClipAgent--e0959";
export var figureClipCallsLeft = "callreview-module--figureClipCallsLeft--816aa";
export var figureClipCampaign = "callreview-module--figureClipCampaign--3c28e";
export var figureClipCircle = "callreview-module--figureClipCircle--c5757";
export var figureClipCoaching = "callreview-module--figureClipCoaching--0f609";
export var figureClipDash = "callreview-module--figureClipDash--07ca9";
export var figureClipGroupPerformance = "callreview-module--figureClipGroupPerformance--d380d";
export var figureClipMissedPoints = "callreview-module--figureClipMissedPoints--e6979";
export var figureClipMyPay = "callreview-module--figureClipMyPay--ed677";
export var figureClipScores = "callreview-module--figureClipScores--0112c";
export var figureCoaching = "callreview-module--figureCoaching--23742";
export var figureContentAgent = "callreview-module--figureContentAgent--15597";
export var figureContentCallsLeft = "callreview-module--figureContentCallsLeft--b3b7c";
export var figureContentCampaign = "callreview-module--figureContentCampaign--0a3df";
export var figureContentCoaching = "callreview-module--figureContentCoaching--897b0";
export var figureContentGroupPerformance = "callreview-module--figureContentGroupPerformance--1aa5d";
export var figureContentMissedPoints = "callreview-module--figureContentMissedPoints--f6615";
export var figureContentMyPay = "callreview-module--figureContentMyPay--67563";
export var figureContentScores = "callreview-module--figureContentScores--47bf3";
export var figureDashboard = "callreview-module--figureDashboard--5efdc";
export var figureEmails = "callreview-module--figureEmails--0eea5";
export var figureFilters = "callreview-module--figureFilters--49d2f";
export var figureFooterImg = "callreview-module--figureFooterImg--6c070";
export var figureGraf = "callreview-module--figureGraf--eca6c";
export var figureGroupPerformance = "callreview-module--figureGroupPerformance--f743a";
export var figureGuidelines = "callreview-module--figureGuidelines--2bfaf";
export var figureMiniReviewAbove = "callreview-module--figureMiniReviewAbove--2eb5b";
export var figureMiniReviewBelow = "callreview-module--figureMiniReviewBelow--149f6";
export var figureMissedPoints = "callreview-module--figureMissedPoints--7a9cd";
export var figureMyPay = "callreview-module--figureMyPay--649c6";
export var figureNIdashboard = "callreview-module--figureNIdashboard--c01e3";
export var figureNotifications = "callreview-module--figureNotifications--0d751";
export var figureScores = "callreview-module--figureScores--39180";
export var filtersCall = "callreview-module--filtersCall--41d25";
export var footerCall = "callreview-module--footerCall--7152e";
export var footerLogo = "callreview-module--footer-logo--b8838";
export var grayCircle = "callreview-module--grayCircle--0367a";
export var greenCircle = "callreview-module--greenCircle--b23be";
export var groupPerformanceCall = "callreview-module--groupPerformanceCall--a10d3";
export var guidelinesCall = "callreview-module--guidelinesCall--92d1d";
export var guidelinesCallText = "callreview-module--guidelinesCallText--291b7";
export var headerCall = "callreview-module--headerCall--12dd7";
export var headerImg = "callreview-module--headerImg--af41d";
export var icomoon = "callreview-module--icomoon--502a2";
export var ideaCall = "callreview-module--ideaCall--5601a";
export var ideaCallText = "callreview-module--ideaCallText--386b3";
export var imgDashboard = "callreview-module--imgDashboard--aa4fc";
export var imgDashboardTop = "callreview-module--imgDashboardTop--b9706";
export var imgDashboardTopContent = "callreview-module--imgDashboardTopContent--05175";
export var imgNIdashboard = "callreview-module--imgNIdashboard--319b8";
export var imgNIdashboardTop = "callreview-module--imgNIdashboardTop--a78c7";
export var imgNIdashboardTopContent = "callreview-module--imgNIdashboardTopContent--ac5d2";
export var imgNIdashboardTopContentVertical = "callreview-module--imgNIdashboardTopContentVertical--03255";
export var imgNotifications = "callreview-module--imgNotifications--4a9d4";
export var imgNotificationsTop = "callreview-module--imgNotificationsTop--d5692";
export var imgNotificationsTopContent = "callreview-module--imgNotificationsTopContent--9f917";
export var logoLable = "callreview-module--logo-lable--16bc3";
export var lowercase = "callreview-module--lowercase--93254";
export var miniReviewAboveCall = "callreview-module--miniReviewAboveCall--343ff";
export var miniReviewBelowCall = "callreview-module--miniReviewBelowCall--06cf0";
export var missedPointsCall = "callreview-module--missedPointsCall--043d5";
export var modulesBottomWave = "callreview-module--modulesBottomWave--8d541";
export var modulesCall = "callreview-module--modulesCall--0aa15";
export var modulesTopWave = "callreview-module--modulesTopWave--15f56";
export var myPayCall = "callreview-module--myPayCall--98f2f";
export var nIDashboardCall = "callreview-module--nIDashboardCall--ed4ef";
export var notificationsCall = "callreview-module--notificationsCall--006f4";
export var redCircle = "callreview-module--redCircle--280ca";
export var row = "callreview-module--row--bca0f";
export var scoresCall = "callreview-module--scoresCall--47f7f";
export var transition = "callreview-module--transition--112f5";
export var transitionBackground = "callreview-module--transition-background--99303";
export var transitionReverse = "callreview-module--transition-reverse--8ac9d";
export var uppercase = "callreview-module--uppercase--48960";
export var yelowCircle = "callreview-module--yelowCircle--63b40";